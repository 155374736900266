import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Store from "../lib/store";
//@ts-ignore
import shopamine from "../images/shopmine.png";
import { Nav, Navbar, Container, Button } from "react-bootstrap";
import { observer } from "mobx-react";
import config from "../config.json";

export default observer(function Header(props: { store: Store }) {
    // const [translateUrl, setTranslateUrl] = useState("");
    // useEffect(() => {
    //    .then((data) => setTranslateUrl(data.data));
    // }, [props]);

    return (
        <Navbar bg="light" expand="lg">
            <Container fluid>
                <Navbar.Brand>
                    <img alt="logo" src={shopamine} width="30" height="30" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/" className={props.store.currentRoute === "status" ? "active" : ""}>
                            Status
                        </Nav.Link>
                        {props.store.can("stores") && (
                            <Nav.Link as={Link} to="/stores" className={props.store.currentRoute === "stores" ? "active" : ""}>
                                Stores
                            </Nav.Link>
                        )}
                        {props.store.can("stores") && (
                            <Nav.Link as={Link} to="/usage" className={props.store.currentRoute === "stores_usage" ? "active" : ""}>
                                Stores usage
                            </Nav.Link>
                        )}
                        {props.store.can("templates") && (
                            <Nav.Link as={Link} to="/skins/only" className={props.store.currentRoute === "skins_only" ? "active" : ""}>
                                Skins
                            </Nav.Link>
                        )}
                        {props.store.can("templates") && (
                            <Nav.Link as={Link} to="/skins" className={props.store.currentRoute === "skins" ? "active" : ""}>
                                Store Skins
                            </Nav.Link>
                        )}
                        {props.store.can("meta") && (
                            <Nav.Link as={Link} to="/meta/permissions" className={props.store.currentRoute === "meta" ? "active" : ""}>
                                Meta admin permissions
                            </Nav.Link>
                        )}
                        {props.store.can("finances") && (
                            <Nav.Link as={Link} to="/finanaces" className={props.store.currentRoute === "finanaces" ? "active" : ""}>
                                Finances
                            </Nav.Link>
                        )}
                        {props.store.can("users") && (
                            <Nav.Link as={Link} to="/users" className={props.store.currentRoute === "users" ? "active" : ""}>
                                Users
                            </Nav.Link>
                        )}
                        {props.store.can("machines") && (
                            <Nav.Link as={Link} to="/machines" className={props.store.currentRoute === "machines" ? "active" : ""}>
                                Machines
                            </Nav.Link>
                        )}
                        {props.store.can("organizations") && (
                            <Nav.Link as={Link} to="/organizations" className={props.store.currentRoute === "organizations" ? "active" : ""}>
                                Organizations
                            </Nav.Link>
                        )}
                        {props.store.isAdmin && <Nav.Link onClick={async () => await props.store.download_admins()}>Admins</Nav.Link>}
                        {props.store.isAdmin && (
                            <Nav.Link
                                active={false}
                                onClick={async () => {
                                    const link = (await props.store.shopamineApi.transalteUrl()).data;

                                    window.open(link, "_newtab", "noopener=true");
                                }}>
                                Translations
                            </Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Text>
                    <Button onClick={() => props.store.logout()}>Logout</Button>
                </Navbar.Text>
            </Container>
        </Navbar>
    );
});
