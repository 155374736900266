import { observer } from "mobx-react";
import React from "react";
import { Button, ButtonGroup, Spinner } from "react-bootstrap";
import Store from "../../lib/store";
import _ from "lodash";
import { DispatchDoamin } from "shared/models";

export default observer(function Actions(props: {
    store: Store; //
    domains: string[];
    dispatchDomain?: DispatchDoamin;
    deactivate_store: () => void;
    is_deactivating_store: boolean;
    is_activating_api: boolean;
    activate_store: () => void;
    is_activating_store: boolean;
    create_backup: () => void;
    is_relaoding_config: boolean;
    reload_store_config: () => void;
    is_reseting_trail_config: boolean;
    chargebee_reset_trail: () => void;
    is_reindex_triggering: boolean;
    trigger_elastic_reindex: () => void;
}) {
    const domains: string[] = props.domains;

    let domain = _.find(domains, (d) => d.indexOf("shopamine") !== -1) as any;

    if (domain === undefined && domains && domains.length > 0) {
        domain = domains[0];
    } else if (domain === undefined) {
        domain = "";
    }

    let rawConfig;
    let adminLegayLogin;
    let checkTemplates;

    if (props.dispatchDomain) {
        if (props.dispatchDomain.useDomainValidator) {
            if (domain.indexOf(".") > 0) {
                rawConfig = "https://" + domain + "/api/custom/editRawConfig?t=" + props.store.config.rawConfigKey;
                adminLegayLogin = "http://" + domain + "/admin/login?adminLegacyLogin";
                checkTemplates = "https://" + domain + "/api/custom/checkTemplates?t=" + props.store.config.rawConfigKey;
            } else {
                rawConfig = "https://" + props.dispatchDomain.domain + "/" + domain + "/api/custom/editRawConfig?t=" + props.store.config.rawConfigKey;
                adminLegayLogin = "http://" + props.dispatchDomain.domain + "/" + domain + "/admin/login?adminLegacyLogin";
                checkTemplates = "https://" + props.dispatchDomain.domain + "/" + domain + "/api/custom/checkTemplates?t=" + props.store.config.rawConfigKey;
            }
        } else {
            rawConfig = "https://" + props.dispatchDomain.domain + "/" + domain + "/api/custom/editRawConfig?t=" + props.store.config.rawConfigKey;
            adminLegayLogin = "http://" + props.dispatchDomain.domain + "/" + domain + "/admin/login?adminLegacyLogin";
            checkTemplates = "https://" + props.dispatchDomain.domain + "/" + domain + "/api/custom/checkTemplates?t=" + props.store.config.rawConfigKey;
        }
    } else if (domain) {
        rawConfig = "https://" + domain + "/api/custom/editRawConfig?t=" + props.store.config.rawConfigKey;
        adminLegayLogin = "http://" + domain + "/admin/login?adminLegacyLogin";
        checkTemplates = "https://" + domain + "/api/custom/checkTemplates?t=" + props.store.config.rawConfigKey;
    } else {
        rawConfig = "#";
        adminLegayLogin = "#";
        checkTemplates = "#";
    }

    return (
        <ButtonGroup>
            <Button variant="primary" as="a" disabled={(!props.domains || props.domains.length === 0) || !props.store.can("legacy_login")} href={adminLegayLogin} target="_blank">
                Admin login
            </Button>
            <Button variant="secondary" as="a" disabled={(!props.domains || props.domains.length === 0) || !props.store.can("raw_config")} href={rawConfig} target="_blank">
                Raw config
            </Button>
            <Button variant="light" as="a" disabled={(!props.domains || props.domains.length === 0) || !props.store.can("templates")} href={checkTemplates} target="_blank">
                Templates verify
            </Button>
            <Button variant="info" onClick={() => props.create_backup()}>
                Create backup
            </Button>
            {props.store.can("shopamine_activate") && (
                <Button variant="dark" onClick={() => props.activate_store()} disabled={props.is_activating_store}>
                    {props.is_activating_store && <Spinner size="sm" as="span" animation="border" />} Activate store
                </Button>
            )}
            {props.store.can("shopamine_deactivate") && (
                <Button variant="danger" onClick={() => props.deactivate_store()} disabled={props.is_deactivating_store}>
                    {props.is_deactivating_store && <Spinner size="sm" as="span" animation="border" />} Deactivate store
                </Button>
            )}
            <Button variant="warning" onClick={() => props.reload_store_config()} disabled={props.is_relaoding_config || !props.store.can("store_reload_config")}>
                {props.is_relaoding_config && <Spinner size="sm" as="span" animation="border" />} Reload store config
            </Button>
            <Button variant="secondary" onClick={() => props.chargebee_reset_trail()} disabled={props.is_reseting_trail_config || !props.store.can("store_reset_trail")}>
                {props.is_reseting_trail_config && <Spinner size="sm" as="span" animation="border" />} Reset chargebee trail
            </Button>
            <Button variant="info" onClick={() => props.trigger_elastic_reindex()}>
                Reindex Elastic
            </Button>
        </ButtonGroup>
    );
});
