import _ from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Button, ButtonGroup, Col, Dropdown, DropdownButton, ListGroup, Row } from "react-bootstrap";
import { DispatchDoamin } from "shared/models";
import Store from "../../lib/store";

export default observer(function Admins(props: { store: Store; admins: any[]; domains: string[]; impersonate: (adminId: number, domain: string) => void; viewAdminUser: (adminId: number) => void; dispatchDomain?: DispatchDoamin }) {
    const admins = [];

    for (const admin of props.admins) {
        // console.log(toJS(admin));

        admins.push(
            <ListGroup.Item key={admin.ID}>
                <Row>
                    <Col>
                        <h5>{admin.Name}</h5>
                        <span>{admin.Email}</span>
                    </Col>
                    <Col className="flex f-j-items-r">
                        <ButtonGroup>
                            <Button variant="info" disabled={!props.store.can("shopamine_login")} onClick={() => props.viewAdminUser(admin.ID)}>
                                View
                            </Button>
                            <DropdownButton as={ButtonGroup} title="Impersonate" disabled={!props.store.can("impersonate_admin")}>
                                {_.map(props.domains, (domain) => {
                                    let url = "";
                                    let displayName = "";
                                    if (props.dispatchDomain) {
                                        if (props.dispatchDomain.useDomainValidator) {
                                            if (domain.indexOf(".") > 0) {
                                                url = `${domain}`;
                                                displayName = domain;
                                            } else {
                                                url = `${props.dispatchDomain.domain + "/" + domain}`;
                                                displayName = props.dispatchDomain.domain + "/" + domain;
                                            }
                                        } else {
                                            url = `${props.dispatchDomain.domain + "/" + domain}`;
                                            displayName = props.dispatchDomain.domain + "/" + domain;
                                        }
                                    } else {
                                        url = `${domain}`;
                                        displayName = domain;
                                    }

                                    return (
                                        <Dropdown.Item key={displayName} onClick={() => props.impersonate(admin.ID, url)}>
                                            {displayName}
                                        </Dropdown.Item>
                                    );
                                })}
                            </DropdownButton>
                        </ButtonGroup>
                    </Col>
                </Row>
            </ListGroup.Item>
        );
    }

    return (
        <>
            <h4>Admins</h4>
            <ListGroup>{admins}</ListGroup>
        </>
    );
});
