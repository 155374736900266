import React from "react";
import Store from "../lib/store";
import { observer } from "mobx-react";
import { action, makeObservable, observable, toJS } from "mobx";
import { NavLink } from "react-router-dom";
import consola from "../config/consola";
import Table from "../ui/Table";
import { Row, Col, Button, Spinner, ButtonGroup, Alert } from "react-bootstrap";
import { timeout } from "../lib/utils/utils";
import GitInfo from "./Skins/GitInfo";
import Popup from "../ui/Popup";
import PopupSimple from "../ui/PopupSimple";
import GitLog from "./Skins/GitLog";
import GitCheckout from "./Skins/GitCheckout";

const logger = consola("stores");

@observer
export default class Skins extends React.Component<{ store: Store }> {
    @observable
    skins: any[] = [];
    @observable
    isLoading = true;
    @observable
    templatesVersion: { [id: string]: any } = {};
    @observable
    instancesInfo: {
        instances: any[];
        activateApi: string[];
    } = {
        instances: [],
        activateApi: [],
    };
    @observable
    currentBranches: { [id: string]: any } = {};
    @observable
    log: any[] = [];
    @observable
    branches: {} = {};

    @observable
    hasError = false;
    @observable
    errorMessage = "";

    @observable
    is_skin_reloading = false;
    @observable
    is_skins_reloading = false;

    @observable
    show_git_log = false;
    @observable
    show_git_checkout = false;
    @observable
    selected_instance?: string = "";
    @observable
    selected_branch?: string = "";

    constructor(props: any) {
        super(props);

        makeObservable(this);
        this.init();
    }

    @action
    init = async () => {
        try {
            this.props.store.setCurrentSelectedRoute("skins");
            this.isLoading = true;

            if (!this.props.store.can("templates")) {
                throw new Error("No access!!!!");
            }

            this.skins = (await this.props.store.skinApi.list()).data;
            this.instancesInfo = (await this.props.store.shopamineApi.info()).data;
            this.templatesVersion = (await this.props.store.templatesApi.version()).data;
            this.currentBranches = (await this.props.store.templatesApi.current_branch()).data;
            this.branches = (await this.props.store.templatesApi.branches()).data;

            console.log(toJS(this.branches));

            await timeout(200);
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.isLoading = false;
        }
    };

    @action
    reload_skin = async (skinId: string) => {
        if (this.is_skin_reloading) {
            return;
        }

        try {
            this.is_skin_reloading = true;

            if (!this.props.store.can("templates")) {
                throw new Error("No access!!!!");
            }

            await this.props.store.skinApi.reload(skinId);

            await timeout(200);
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_skin_reloading = false;
        }
    };

    @action
    reload_skins = async () => {
        try {
            this.is_skins_reloading = true;

            if (!this.props.store.can("templates")) {
                throw new Error("No access!!!!");
            }

            await this.props.store.skinApi.reload_all();

            await timeout(200);
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_skins_reloading = false;
        }
    };

    @action
    update = async (instance: string) => {
        try {
            if (!this.props.store.can("templates")) {
                throw new Error("No access!!!!");
            }

            await this.props.store.templatesApi.update(instance);

            await this.init();

            await timeout(200);
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        }
    };

    @action
    git_log = async () => {
        try {
            if (!this.props.store.can("templates")) {
                throw new Error("No access!!!!");
            }

            if (!this.selected_instance) {
                throw new Error("Can't switch branch missing instance");
            }

            this.log = (await this.props.store.templatesApi.log(this.selected_instance)).data;

            await timeout(200);
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.selected_instance = undefined;
        }
    };

    @action
    switchBranch = async () => {
        try {
            if (!this.props.store.can("templates")) {
                throw new Error("No access!!!!");
            }

            if (!this.selected_instance) {
                throw new Error("Can't switch branch missing instance");
            }

            if (!this.selected_branch) {
                throw new Error("Can't switch branch missing branch");
            }

            if(this.selected_branch.startsWith("origin/")) {
                this.selected_branch = this.selected_branch.replaceAll("origin/", "");
            }

            await this.props.store.templatesApi.checkout(this.selected_instance, this.selected_branch);

            await timeout(200);
            await this.init();
        } catch (eRaw) {
            const e = eRaw as any;
            this.show_git_checkout = false;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.selected_instance = undefined;
            this.selected_branch = undefined;
            this.show_git_checkout = false;
        }
    };

    @action
    selectBranch = (branch?: string) => {
        if (branch) this.selected_branch = branch;
    };

    render() {
        const columns = [
            {
                Header: "ID",
                disableSortBy: false,
                accessor: "storeid",
            },
            {
                Header: "Store Name",
                disableSortBy: false,
                accessor: "store",
            },
            {
                Header: "Name",
                disableSortBy: false,
                accessor: "name",
            },
            {
                Header: "Skin Id",
                disableSortBy: false,
                accessor: "global_skin",
            },
            {
                Header: "Skin name",
                disableSortBy: false,
                accessor: "global_skin_name",
            },
            {
                Header: "Action",
                disableSortBy: true,
                actions: (row: any) => {
                    return (
                        <>
                            <NavLink to={"/store/" + row.original.storeid}>View</NavLink>
                            &nbsp;
                            <a className={!this.is_skin_reloading ? "clickable" : ""} onClick={() => this.reload_skin(row.original.id)}>
                                {this.is_skin_reloading && (
                                    <>
                                        <Spinner size="sm" as="span" animation="border" />{" "}
                                    </>
                                )}
                                Reload skin
                            </a>
                        </>
                    );
                },
            },
        ];

        return (
            <>
                <Row>
                    <Col className="mb-20">
                        <h2>Store Skins</h2>

                        <Alert show={this.hasError} variant="danger">
                            {this.errorMessage}
                        </Alert>

                        <GitInfo
                            instancesInfo={this.instancesInfo}
                            templatesVersion={this.templatesVersion}
                            currentBranches={this.currentBranches}
                            checkout={action((instance: string) => {
                                this.selected_instance = instance;
                                this.show_git_checkout = true;
                            })}
                            log={action((instance: string) => {
                                this.selected_instance = instance;
                                this.show_git_log = true;

                                this.git_log();
                            })}
                            update={this.update}
                        />

                        <ButtonGroup>
                            <Button variant="primary" onClick={() => this.reload_skins()} disabled={this.is_skins_reloading}>
                                {this.is_skins_reloading && (
                                    <>
                                        <Spinner size="sm" as="span" animation="border" />{" "}
                                    </>
                                )}
                                Reload All Skins
                            </Button>
                            <Button variant="secondary" onClick={() => this.reload_skin("-2")} disabled={this.is_skins_reloading}>
                                {this.is_skins_reloading && (
                                    <>
                                        <Spinner size="sm" as="span" animation="border" />{" "}
                                    </>
                                )}
                                Reload System Skins
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="mb-20">
                        <Table data={this.skins} columns={columns} />
                    </Col>
                </Row>

                <PopupSimple show={this.show_git_log} onClose={action(() => (this.show_git_log = false))} title="Git history" size="lg">
                    <GitLog instancesInfo={this.instancesInfo} templatesVersion={this.templatesVersion} currentBranches={this.currentBranches} log={this.log} />
                </PopupSimple>

                <Popup show={this.show_git_checkout} onClose={action(() => (this.show_git_checkout = false))} title="Checkout" size="lg" onSave={this.switchBranch} save="Switch branch">
                    <GitCheckout instancesInfo={this.instancesInfo} templatesVersion={this.templatesVersion} currentBranches={this.currentBranches} avalibleBranches={this.branches} selectBranch={this.selectBranch} />
                </Popup>
            </>
        );
    }
}
