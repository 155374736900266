import React from "react";
import Store from "../lib/store";
import { observer } from "mobx-react";
import { action, makeObservable, observable, toJS } from "mobx";
import { NavLink } from "react-router-dom";
import consola from "../config/consola";
import { timeout } from "../lib/utils/utils";
import { StoreCreatorData, ShopamineInstance, DispatchDoamin } from "shared/models";
import Table from "../ui/Table";
import { Row, Col, Button, Form, Alert, ButtonGroup, Spinner } from "react-bootstrap";
import Popup from "../ui/Popup";
import _ from "lodash";

const logger = consola("stores");

@observer
export default class Stores extends React.Component<{ store: Store }> {
    emailRegEx = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/);
    domainRegEx = new RegExp(/^[a-z][a-z0-9-]*[a-z0-9]$/);
    @observable
    storesList: any[] = [];
    @observable
    isLoading = true;

    @observable
    hasError = false;
    @observable
    errorMessage = "";

    @observable
    instances: ShopamineInstance[] = [];
    @observable
    selected_instance?: ShopamineInstance;
    @observable
    sample_data_keys: string[] = [];

    @observable
    create_store_popup = false;
    @observable
    create_store_error?: string;
    @observable
    create_store_email?: string;
    @observable
    create_store_instance?: string;
    @observable
    create_store_data: StoreCreatorData = {
        adminEmail: "",
        domainPart: "",
        locale: "",
        domainSuffix: "",
        storeName: "",
        country: ""
    };
    @observable
    useDispatchDomain = false;
    @observable
    dispatchDomain?: DispatchDoamin;

    @observable
    is_impersonation_in_progress = false;
    @observable
    is_refresh_in_progress = false;

    @observable
    filter: "active" | "disabled" = "active";

    constructor(props: any) {
        super(props);
        makeObservable(this);

        this.load();
    }

    @action
    load = async () => {
        try {
            this.props.store.setCurrentSelectedRoute("stores");
            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            this.isLoading = true;

            this.storesList = (await this.props.store.storesApi.list(this.filter)).data;
            const data = (await this.props.store.shopamineApi.info()).data;
            this.instances = data.instances;

            this.useDispatchDomain = data.useDispatchDomain;
            this.dispatchDomain = data.dispatchDomain;
        } catch (eRaw) {
            //Some idot at typescript made exception of type unknwon ...
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                logger.error("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        } finally {
            this.isLoading = false;
        }
    };

    @action
    load_samples = async () => {
        //create_store_samples
        try {
            if (!this.selected_instance) {
                throw new Error("Please select instance");
            }

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            this.sample_data_keys = (await this.props.store.storesApi.create_store_samples(this.selected_instance.name)).data.data;
        } catch (eRaw) {
            const e = eRaw as any;
            logger.error(e);
            this.create_store_error = `${e.message || e}`;

            await timeout(3000);
            this.create_store_error = undefined;
        }
    };

    @action
    create_store = async () => {
        try {
            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            if (!this.create_store_email) {
                throw new Error("No email was provided ... ");
            }

            if (!this.emailRegEx.test(this.create_store_email)) {
                throw new Error("Invalid email");
            }

            if (!this.create_store_instance || this.create_store_instance === "-1") {
                throw new Error("Please select instance");
            }

            //validate store data
            if (!this.emailRegEx.test(this.create_store_data.adminEmail)) {
                throw new Error("Invalid admin email");
            }

            // this.create_store_data.storeName = this.create_store_data.storeName.trim();
            // if (this.create_store_data.storeName.length < 3) {
            //     throw new Error("Store name missing");
            // }

            // this.create_store_data.domainPart = this.create_store_data.domainPart.trim();
            // if (this.create_store_data.domainPart.length < 3 || !this.domainRegEx.test(this.create_store_data.domainPart)) {
            //     throw new Error("Invalid domain part");
            // }

            if (!this.create_store_data.domainSuffix || this.create_store_data.domainSuffix === "-1") {
                throw new Error("Please select domain suffix");
            }

            if (!this.create_store_data.locale || this.create_store_data.locale === "-1") {
                throw new Error("Please select locale");
            }

            await this.props.store.storesApi.create_store(this.create_store_email, this.create_store_instance, this.create_store_data);

            this.create_store_popup = false;
            this.create_store_email = undefined;
            this.create_store_instance = undefined;
            this.create_store_data = {
                adminEmail: "",
                domainPart: "",
                locale: "",
                domainSuffix: "",
                storeName: "",
                country: ""
            };
        } catch (eRaw) {
            const e = eRaw as any;
            logger.error(e);
            this.create_store_error = `${e.message || e}`;

            await timeout(3000);
            this.create_store_error = undefined;
        }
    };

    @action
    impersonateAdmin = async (loadingInstance: string, storeId: number, domain: string) => {
        try {
            this.is_impersonation_in_progress = true;

            if (!this.props.store.can("impersonate_admin")) {
                throw new Error("No access!!!!");
            }

            const admins = (await this.props.store.storeApi.admins(storeId)).data;
            const admin = _.find(admins, (admin) => admin.Email === "admin@appoteka.com");

            const url = (await this.props.store.storeApi.impersonate_admin(loadingInstance, storeId, admin.ID, domain)).data;

            window.open(url, "_newtab", "noopener=true");
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_impersonation_in_progress = false;
        }
    };

    @action
    refreshStoreIndex = async () => {
        try {
            this.is_refresh_in_progress = true;

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            await this.props.store.systemApi.refreshStoreIndex();
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_refresh_in_progress = false;
        }
    };

    @action
    updateFilter = async (filter: "active" | "disabled") => {
        this.filter = filter;
        await this.load();
    };

    render() {
        const columns = [
            {
                Header: "ID",
                disableSortBy: false,
                accessor: "storeid",
            },
            {
                Header: "Store Name",
                disableSortBy: false,
                accessor: "Store_Name",
            },
            {
                Header: "Domain Part",
                disableSortBy: false,
                accessor: "Domain_Part",
            },
            {
                Header: "Admin Email",
                disableSortBy: false,
                accessor: "Admin_Email",
            },
            {
                Header: "Company",
                disableSortBy: false,
                accessor: "company_name",
            },
            // {
            //     Header: "VAT",
            //     disableSortBy: false,
            //     accessor: "company_vat",
            //     actions: (row: any) => {
            //         return `${row.original.company_vat_prefix ? row.original.company_vat_prefix : ""}${row.original.company_vat}`;
            //     },
            // },
            {
                Header: "Plan",
                disableSortBy: false,
                accessor: "ChargeBeePlan",
                actions: (row: any) => {
                    return row.original.plan_id ? row.original.plan_id : row.original.ChargeBeePlan;
                },
            },
            {
                Header: "Status",
                disableSortBy: false,
                accessor: "ChargeBeeStatus",
                actions: (row: any) => {
                    return row.original.status ? row.original.status : row.original.ChargeBeeStatus;
                },
            },
            {
                Header: "Deleted",
                disableSortBy: false,
                accessor: "deleted",
            },
            {
                Header: "Published",
                disableSortBy: false,
                accessor: "published",
                actions: (row: any) => {
                    return row.original.published ? "yes" : "no";
                },
            },
            {
                Header: "Loading Instances",
                disableSortBy: false,
                accessor: "Loading_Instances",
            },
            {
                Header: "Action",
                disableSortBy: true,
                actions: (row: any) => {
                    const domains: string[] = row.original.domains ? row.original.domains.split(",") : [];

                    let domain = _.find(domains, (d) => d.indexOf("shopamine") !== -1) as any;

                    if (domain === undefined && domains.length > 0) {
                        domain = domains[0];
                    } else if (domain === undefined) {
                        domain = "";
                    }

                    let rawConfig;
                    let impersonateDomain = domain;

                    if (this.useDispatchDomain && this.dispatchDomain) {
                        if (this.dispatchDomain.useDomainValidator) {
                            if (domain.indexOf(".") > 0) {
                                rawConfig = "https://" + domain + "/api/custom/editRawConfig?t=" + this.props.store.config.rawConfigKey;
                            } else {
                                impersonateDomain = this.dispatchDomain.domain + "/" + domain;
                                rawConfig = "https://" + this.dispatchDomain.domain + "/" + domain + "/api/custom/editRawConfig?t=" + this.props.store.config.rawConfigKey;
                            }
                        } else {
                            impersonateDomain = this.dispatchDomain.domain + "/" + domain;
                            rawConfig = "https://" + this.dispatchDomain.domain + "/" + domain + "/api/custom/editRawConfig?t=" + this.props.store.config.rawConfigKey;
                        }
                    } else if (domain) {
                        rawConfig = "https://" + domain + "/api/custom/editRawConfig?t=" + this.props.store.config.rawConfigKey;
                    } else {
                        rawConfig = "#";
                    }

                    const instances = _.chain((row.original.Loading_Instances ? row.original.Loading_Instances : "").split(","))
                        .map((p: string) => p.trim().replace("instance_", ""))
                        .uniq()
                        .value() as any[];

                    return (
                        <>
                            <button
                                disabled={!this.props.store.can("impersonate_admin")}
                                onClick={() => {
                                    this.impersonateAdmin(instances[0], Number.parseInt(row.original.storeid), impersonateDomain);
                                }}>
                                Admin
                            </button>
                            &nbsp;
                            <a href={this.props.store.can("raw_config") ? rawConfig : "#"} target="_blank" className={!this.props.store.can("raw_config") ? "disabled" : ""} rel="noreferrer">
                                RawConfig
                            </a>
                            &nbsp;
                            <NavLink to={"/store/" + row.original.storeid}>View</NavLink>
                        </>
                    );
                },
            },
        ];

        return (
            <>
                <Row>
                    <Col className="mb-20">
                        <h2>Stores</h2>

                        <Alert show={this.hasError} variant="danger">
                            {this.errorMessage}
                        </Alert>
                        <ButtonGroup>
                            <Button variant="primary" onClick={action(() => (this.create_store_popup = true))}>
                                Create store
                            </Button>
                            <Button variant="info" onClick={this.refreshStoreIndex}>
                                {this.is_refresh_in_progress && <Spinner size="sm" as="span" animation="border" />} Refresh store index
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    this.updateFilter(this.filter === "active" ? "disabled" : "active");
                                }}>
                                Show {this.filter === "active" ? "disabled" : "active"}
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Table data={this.storesList} columns={columns} hiddenColumns={["Domain_Part"]} />
                    </Col>
                </Row>

                <Popup onClose={action(() => (this.create_store_popup = false))} onSave={this.create_store} show={this.create_store_popup} title="Create store" save="Create store">
                    <Alert show={!!this.create_store_error} variant="danger">
                        {this.create_store_error}
                    </Alert>

                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text" value={this.create_store_email} onChange={action((e) => (this.create_store_email = e.target.value))} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Instances</Form.Label>
                        <Form.Select
                            value={this.create_store_instance}
                            onChange={action((e) => {
                                this.create_store_instance = e.target.value;
                                this.selected_instance = _.find(this.instances, (p) => p.name === this.create_store_instance);

                                this.load_samples();
                            })}>
                            <option value="-1">Select ...</option>
                            {this.instances.map((item) => (
                                <option key={item.name} value={item.name}>
                                    {item.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <h3>Store information</h3>

                    <Form.Group className="mb-3">
                        <Form.Label>Store name</Form.Label>
                        <Form.Control type="text" value={this.create_store_data.storeName} onChange={action((e) => (this.create_store_data.storeName = e.target.value))} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Domain part</Form.Label>
                        <Form.Control type="text" value={this.create_store_data.domainPart} onChange={action((e) => (this.create_store_data.domainPart = e.target.value))} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Domain</Form.Label>
                        <Form.Select value={this.create_store_data.domainSuffix} onChange={action((e) => (this.create_store_data.domainSuffix = e.target.value))}>
                            <option value="-1">Select ...</option>
                            {this.selected_instance &&
                                this.selected_instance.domains.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Local</Form.Label>
                        <Form.Select value={this.create_store_data.locale} onChange={action((e) => (this.create_store_data.locale = e.target.value))}>
                            <option value="-1">Select ...</option>
                            {this.selected_instance &&
                                this.selected_instance.langs.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                        </Form.Select>
                    </Form.Group>


                    <Form.Group className="mb-3">
                        <Form.Label>Country config</Form.Label>
                        <Form.Select value={this.create_store_data.country} onChange={action((e) => (this.create_store_data.country = e.target.value))}>
                            <option value="-1">Select ...</option>
                            {this.selected_instance &&
                                this.selected_instance.countries.map((item) => (
                                    <option key={item} value={item}>
                                        {item}
                                    </option>
                                ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Sample data</Form.Label>
                        <Form.Select value={this.create_store_data.sampleDataKey} onChange={action((e) => (this.create_store_data.sampleDataKey = e.target.value))}>
                            <option value="-1">Select ...</option>
                            {this.sample_data_keys.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Admin email</Form.Label>
                        <Form.Control type="text" value={this.create_store_data.adminEmail} onChange={action((e) => (this.create_store_data.adminEmail = e.target.value))} />
                    </Form.Group>

                    <p>NOTE: You will be informed about the creation of the store to provided email address ... This may take several minutes.</p>
                </Popup>
            </>
        );
    }
}
