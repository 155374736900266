import React from "react";
import { observer } from "mobx-react";
import { FormSelect } from "react-bootstrap";
import _ from "lodash";

export default observer(function GitCheckout(props: {
    instancesInfo: {
        instances: any[];
        activateApi: string[];
    };
    templatesVersion: { [id: string]: any };
    currentBranches: { [id: string]: string };
    avalibleBranches: {
        [id: string]: {
            local: string[];
            remote: string[];
        };
    };
    selectBranch: (branch: string) => void;
}) {
    return (
        <>
            <FormSelect
                onChange={(e) => {
                    props.selectBranch(e.target.value);
                }}>
                {_.map(props.avalibleBranches, (b) => (
                    <>
                        <optgroup label="Local">
                            {_.map(b.local, (l) => (
                                <option value={l}>{l}</option>
                            ))}
                        </optgroup>
                        <optgroup label="Remote">
                            {_.map(b.remote, (l) => (
                                <option value={l}>{l}</option>
                            ))}
                        </optgroup>
                    </>
                ))}
            </FormSelect>
        </>
    );
});
