import { observer } from "mobx-react";
import React from "react";
import Store from "../../lib/store";
import { action, makeObservable, observable } from "mobx";
import { Alert, Button, ButtonGroup } from "react-bootstrap";
import { timeout } from "../../lib/utils/utils";
import _ from "lodash";

@observer
export default class Deploy extends React.Component<{ store: Store; match?: any }> {
    @observable
    hasError = false;
    @observable
    errorMessage = "";

    @observable
    status: any = {};
    @observable
    event?: any;
    @observable
    lastHistory?: string;

    constructor(props: any) {
        super(props);

        makeObservable(this);
        this.init();

        this.props.store.on("deploy.update", this.data_update);
        this.props.store.on("deploy.done", this.deploy_done);
        this.props.store.on("deploy.starting", this.deploy_start);
    }

    @action
    init = async () => {
        try {
            if (!this.props.store.can("shopamine_blue_green")) {
                throw new Error("No access!!!!");
            }

            const status = (await this.props.store.deployApi.status()).data;

            this.status = status;
        } catch (eRaw) {
            //Some idot at typescript made exception of type unknwon ...
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.error("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    componentWillUnmount = () => {
        this.props.store.off("deploy.update", this.data_update);
        this.props.store.off("deploy.done", this.deploy_done);
        this.props.store.off("deploy.starting", this.deploy_start);
    };

    @action
    data_update = async (data: any) => {
        console.log(data);
        this.lastHistory = data;
    };

    @action
    deploy_start = async (data: any) => {
        console.log(data);

        this.event = data;
    };

    @action
    deploy_done = async (data: any) => {
        console.log(data);

        this.event = undefined;
        this.lastHistory = undefined;

        await this.init();
    };

    @action
    rolling_restart = async () => {
        try {
            if (!this.props.store.can("shopamine_blue_green")) {
                throw new Error("No access!!!!");
            }

            const fromInstances = _.filter(this.status.availibleInstances, (item) => item !== this.status.preferredInstance);

            if (fromInstances.length !== 1) {
                throw new Error(`Can't detect from instance!`);
            }

            await this.props.store.deployApi.rolling_restart(fromInstances[0]);
        } catch (eRaw) {
            //Some idot at typescript made exception of type unknwon ...
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.error("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    @action
    transfer = async () => {
        try {
            if (!this.props.store.can("shopamine_blue_green")) {
                throw new Error("No access!!!!");
            }

            const fromInstances = _.filter(this.status.availibleInstances, (item) => item !== this.status.requestedInstance);

            if (fromInstances.length !== 1) {
                throw new Error(`Can't detect from instance!`);
            }

            console.log(fromInstances);

            await this.props.store.deployApi.transfer(fromInstances[0]);
        } catch (eRaw) {
            //Some idot at typescript made exception of type unknwon ...
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.error("here", e.response.data);
                this.errorMessage = e.response.data.errMsg;
            } else {
                this.errorMessage = `${e}`;
            }

            this.hasError = true;

            await timeout(2000);
            this.hasError = false;
        }
    };

    render() {
        return (
            <>
                <h2>Blue-Green</h2>

                <Alert show={this.hasError} variant="danger">
                    {this.errorMessage}
                </Alert>

                <pre className="p-1">
                    Only on predefined set or instances / stores {"\n"}
                    {"\n"}
                    Current selected instance: {this.status.requestedInstance} {"\n"}
                    Availible instanaces: {this.status.availibleInstances && this.status.availibleInstances.join(", ")} {"\n"}
                    {"\n"}
                    Current operation: {(this.event && this.event.type) || (this.status.lock && this.status.lock.type)} {"\n"}
                    Current msg: {this.lastHistory} {"\n"}
                </pre>

                <ButtonGroup>
                    <Button disabled={!this.props.store.can("shopamine_blue_green")} onClick={this.rolling_restart}>
                        Rolling restart
                    </Button>
                    <Button disabled={!this.props.store.can("shopamine_blue_green")} onClick={this.transfer} variant="info">
                        Start transfer
                    </Button>
                </ButtonGroup>
            </>
        );
    }
}
