import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Organization, OrganizationAccessor, OrganizationMember, OrganizationPermission, OrganizationStores, User } from "shared/models";
import Store from "../lib/store";
import { timeout } from "../lib/utils/utils";
import { Alert, Button, Col, Form, ListGroup, Row } from "react-bootstrap";
import Table from "../ui/Table";
import Popup from "../ui/Popup";
import _ from "lodash";
import PopupSimple from "../ui/PopupSimple";

type OrganizationDialogType = "member" | "permission" | "store";

@observer
export default class Organizations extends React.Component<{ store: Store }> {
    @observable
    isLoading = true;
    @observable
    organizations: OrganizationAccessor[] = [];
    @observable
    users: User[] = [];
    @observable
    permissions: string[] = [];
    @observable
    stores: any[] = [];

    @observable
    hasError = false;
    @observable
    errorMessage = "";

    @observable
    popupHasError = false;
    @observable
    popupErrorMessage = "";

    @observable
    show_add_update_popup = false;
    @observable
    show_delete_confirm = false;
    @observable
    show_view_organization = false;
    @observable
    show_view_organization_add_dialog = false;

    @observable
    organization: Organization = {
        name: "",
        admin: false,
        ownerId: -1,
    };
    @observable
    toDelete: number = -1;
    @observable
    organizationFull: OrganizationAccessor = {
        name: "",
        admin: false,
        ownerId: -1,
        members: [],
        permissions: [],
        stores: [],
    };
    @observable
    organizationDialogType: OrganizationDialogType = "member";
    @observable
    organizationMemberUserId = -1;
    @observable
    organizationPermission = "";
    @observable
    organizationStoreId = -1;

    constructor(props: any) {
        super(props);

        makeObservable(this);
        this.init();
    }

    @action
    init = async () => {
        try {
            this.props.store.setCurrentSelectedRoute("organizations");
            if (!this.props.store.can("organizations")) {
                throw new Error("No access!!!!");
            }

            this.organizations = (await this.props.store.organizationsApi.list()).data;
            this.users = (await this.props.store.usersApi.list()).data;
            this.permissions = (await this.props.store.usersApi.getAvaliblePermissions()).data;
            this.stores = (await this.props.store.storesApi.list("active", true)).data;

            this.isLoading = true;
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.isLoading = false;
        }
    };

    @action
    add_organization = async () => {
        this.show_add_update_popup = true;
    };

    @action
    edit_organization = async (organization: Organization) => {
        this.show_add_update_popup = true;
        this.organization = organization;
    };

    @action
    save_organization = async () => {
        try {
            if (!this.props.store.can("organizations")) {
                throw new Error("No access!!!!");
            }

            if (this.organization.ownerId === -1) {
                throw new Error("Please select organization owener!");
            }

            if (this.organization.id) {
                await this.props.store.organizationsApi.update(this.organization.id, this.organization);
            } else {
                await this.props.store.organizationsApi.add(this.organization);
            }

            this.show_add_update_popup = false;

            this.organization = {
                name: "",
                admin: false,
                ownerId: -1,
            };

            await this.init();
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.popupErrorMessage = e.response.data.errMsg;
                } else {
                    this.popupErrorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    @action
    update_prganization_by_type = async () => {
        try {
            if (!this.props.store.can("organizations")) {
                throw new Error("No access!!!!");
            }

            if (!this.organizationFull.id) {
                throw new Error("Organization not selected!");
            }

            switch (this.organizationDialogType) {
                case "member":
                    await this.props.store.organizationsApi.add_member(this.organizationFull.id, this.organizationMemberUserId);
                    break;
                case "permission":
                    await this.props.store.organizationsApi.add_permission(this.organizationFull.id, this.organizationPermission);
                    break;
                case "store":
                    await this.props.store.organizationsApi.add_store(this.organizationFull.id, this.organizationStoreId);
                    break;
            }

            this.show_view_organization_add_dialog = false;

            await this.init();

            const org = _.find(this.organizations, (o) => o.id === this.organizationFull.id);

            if (org) {
                this.organizationFull = org;
            }
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.popupErrorMessage = e.response.data.errMsg;
                } else {
                    this.popupErrorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    @action
    remove_organizaation = async () => {
        try {
            if (!this.props.store.can("organizations")) {
                throw new Error("No access!!!!");
            }

            await this.props.store.organizationsApi.remove(this.toDelete);
            this.show_delete_confirm = false;

            await this.init();
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.popupErrorMessage = e.response.data.errMsg;
                } else {
                    this.popupErrorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    @action
    remove_organization_by_type = async (organizationDialogType: OrganizationDialogType, id?: number) => {
        try {
            if (!this.props.store.can("organizations")) {
                throw new Error("No access!!!!");
            }

            if (!id) {
                throw new Error("Please provide id");
            }

            switch (organizationDialogType) {
                case "member":
                    await this.props.store.organizationsApi.remove_member(id);
                    break;
                case "permission":
                    await this.props.store.organizationsApi.remove_permission(id);
                    break;
                case "store":
                    await this.props.store.organizationsApi.remove_store(id);
                    break;
            }

            await this.init();

            const org = _.find(this.organizations, (o) => o.id === this.organizationFull.id);

            if (org) {
                this.organizationFull = org;
            }
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.popupErrorMessage = e.response.data.errMsg;
                } else {
                    this.popupErrorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    @action
    open_remove_organizaation = async (id: number) => {
        this.show_delete_confirm = true;
        this.toDelete = id;
    };

    @action
    open_view_organization = async (organization: OrganizationAccessor) => {
        this.organizationFull = organization;
        this.show_view_organization = true;
    };

    @action
    open_add_dialog = (type: OrganizationDialogType) => {
        this.show_view_organization_add_dialog = true;
        this.organizationDialogType = type;
    };

    @action
    render() {
        const columns = [
            {
                Header: "ID",
                disableSortBy: false,
                accessor: "id",
            },
            {
                Header: "Name",
                disableSortBy: false,
                accessor: "name",
            },
            {
                Header: "Admin",
                disableSortBy: false,
                accessor: "admin",
                actions: (row: any) => {
                    return <>{row.original.admin ? "yes" : "no"}</>;
                },
            },
            {
                Header: "Owner",
                disableSortBy: false,
                actions: (row: any) => {
                    const user = _.find(this.users, (u) => row.original.ownerId === u.id);

                    if (user) {
                        return <>{user.username}</>;
                    }
                    return <></>;
                },
            },
            {
                Header: "Action",
                disableSortBy: true,
                actions: (row: any) => {
                    return (
                        <>
                            <a className="clickable" onClick={() => this.edit_organization(row.original)}>
                                Edit
                            </a>
                            &nbsp;
                            <a className="clickable" onClick={() => this.open_remove_organizaation(row.original.id)}>
                                Delete
                            </a>
                            &nbsp;
                            <a className="clickable" onClick={() => this.open_view_organization(row.original)}>
                                View
                            </a>
                        </>
                    );
                },
            },
        ];

        return (
            <>
                <Row>
                    <Col className="mb-20">
                        <h2>Organizations</h2>

                        <Alert show={this.hasError} variant="danger">
                            {this.errorMessage}
                        </Alert>

                        <Button variant="primary" onClick={() => this.add_organization()}>
                            Add organization
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Table columns={columns} data={this.organizations} />
                    </Col>
                </Row>

                <Popup show={this.show_delete_confirm} onClose={action(() => (this.show_delete_confirm = false))} onSave={this.remove_organizaation} title="Delete machine?" save="Delete"></Popup>

                <Popup onClose={action(() => (this.show_add_update_popup = false))} onSave={this.save_organization} show={this.show_add_update_popup} title={"Organization editor"}>
                    <Alert show={this.popupHasError} variant="danger">
                        {this.popupErrorMessage}
                    </Alert>

                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" value={this.organization.name} onChange={action((e) => (this.organization.name = e.target.value))} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Owner</Form.Label>
                        <Form.Control as="select" onChange={action((e) => (this.organization.ownerId = parseInt(e.target.value)))} value={this.organization.ownerId}>
                            <option value="-1">select ...</option>
                            {_.map(this.users, (u) => (
                                <option value={u.id}>{u.username}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Check type="switch" label="Admin" checked={this.organization.admin} onChange={action((e) => (this.organization.admin = e.target.checked))} />
                    </Form.Group>
                </Popup>

                <PopupSimple onClose={action(() => (this.show_view_organization = false))} show={this.show_view_organization} title={"Organization"} size="xl">
                    <h3>Organization {this.organizationFull.name}</h3>

                    <Row className="mb-3">
                        <Col>
                            <label>Members</label>
                            <ListGroup className="mb-3">
                                {_.map(this.organizationFull.members, (e) => (
                                    <ListGroup.Item>
                                        {_.find(this.users, (u) => e.userId === u.id)?.username}&nbsp;
                                        <Button size="sm" variant="danger" onClick={() => this.remove_organization_by_type("member", e.id)}>
                                            Remove
                                        </Button>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                            <Button onClick={() => this.open_add_dialog("member")}>Add</Button>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <label>Stores</label>
                            <ListGroup className="mb-3">
                                {_.map(this.organizationFull.stores, (e) => {
                                    const store = _.find(this.stores, (u) => e.storeId === u.storeid);
                                    return (
                                        <ListGroup.Item>
                                            {store?.storeid} {store?.Store_Name}&nbsp;
                                            <Button size="sm" variant="danger" onClick={() => this.remove_organization_by_type("store", e.id)}>
                                                Remove
                                            </Button>
                                        </ListGroup.Item>
                                    );
                                })}
                            </ListGroup>
                            <Button onClick={() => this.open_add_dialog("store")}>Add</Button>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <label>Permissions</label>
                            <ListGroup className="mb-3">
                                {_.map(this.organizationFull.permissions, (e) => (
                                    <ListGroup.Item>
                                        {e.permission}&nbsp;
                                        <Button size="sm" variant="danger" onClick={() => this.remove_organization_by_type("permission", e.id)}>
                                            Remove
                                        </Button>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                            <Button onClick={() => this.open_add_dialog("permission")}>Add</Button>
                        </Col>
                    </Row>
                </PopupSimple>

                <Popup onClose={action(() => (this.show_view_organization_add_dialog = false))} onSave={this.update_prganization_by_type} show={this.show_view_organization_add_dialog} title={"Organization edit: " + this.organizationDialogType}>
                    {this.organizationDialogType === "member" && (
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label>Member</Form.Label>
                                <Form.Control as="select" onChange={action((e) => (this.organizationMemberUserId = parseInt(e.target.value)))} value={this.organizationMemberUserId}>
                                    <option value="-1">select ...</option>
                                    {_.map(this.users, (u) => (
                                        <option value={u.id}>{u.username}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </>
                    )}
                    {this.organizationDialogType === "store" && (
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label>Store</Form.Label>
                                <Form.Control as="select" onChange={action((e) => (this.organizationStoreId = parseInt(e.target.value)))} value={this.organizationStoreId}>
                                    <option value="-1">select ...</option>
                                    {_.map(this.stores, (s) => (
                                        <option value={s.storeid}>
                                            {s.storeid} {s.Store_Name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </>
                    )}
                    {this.organizationDialogType === "permission" && (
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label>Permission</Form.Label>
                                <Form.Control as="select" onChange={action((e) => (this.organizationPermission = e.target.value))} value={this.organizationPermission}>
                                    <option value="-1">select ...</option>
                                    {_.map(this.permissions, (p) => (
                                        <option value={p}>{p}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </>
                    )}
                </Popup>
            </>
        );
    }
}
