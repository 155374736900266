import { Organization } from "shared/models";
import Api from "../utils/api";

export default class OrganizationApi extends Api {
    private static PREFIX = "/api/v1/organizations";

    public async list() {
        return (await this.axios.get(`${OrganizationApi.PREFIX}/`)).data;
    }

    public async add(organization: Organization) {
        return (await this.axios.post(`${OrganizationApi.PREFIX}/add`, organization)).data;
    }

    public async update(id: number, organization: Organization) {
        return (await this.axios.post(`${OrganizationApi.PREFIX}/update/${id}`, organization)).data;
    }

    public async remove(id: number) {
        return (await this.axios.post(`${OrganizationApi.PREFIX}/remove/${id}`, {})).data;
    }

    public async add_member(id: number, userId: number) {
        return (await this.axios.post(`${OrganizationApi.PREFIX}/${id}/members/add`, { userId })).data;
    }

    public async add_permission(id: number, permission: string) {
        return (await this.axios.post(`${OrganizationApi.PREFIX}/${id}/permissions/add`, { permission })).data;
    }

    public async add_store(id: number, storeId: number) {
        return (await this.axios.post(`${OrganizationApi.PREFIX}/${id}/store/add`, { storeId })).data;
    }

    public async remove_member(id: number) {
        return (await this.axios.post(`${OrganizationApi.PREFIX}/${id}/members/remove`, {})).data;
    }

    public async remove_permission(id: number) {
        return (await this.axios.post(`${OrganizationApi.PREFIX}/${id}/permissions/remove`, {})).data;
    }

    public async remove_store(id: number) {
        return (await this.axios.post(`${OrganizationApi.PREFIX}/${id}/store/remove`, {})).data;
    }
}