import React from "react";
import { observer } from "mobx-react";
import Store from "./lib/store";
import Stores from "./pages/Stores";
import StoreShop from "./pages/StoreShop";
import StoresUsage from "./pages/StoresUsage";
import { BrowserRouter, Route, Link, Routes } from "react-router-dom";
import Skins from "./pages/Skins";
import { action } from "mobx";
import Finanaces from "./pages/Finanaces";
import FinanacesView from "./pages/FinanacesView";
import SkinsOnly from "./pages/SkinsOnly";
import Status from "./pages/Status";
import Header from "./components/Menu";
import { Container, Alert, Row, Col } from "react-bootstrap";
import Login from "./pages/Login";
import MetaAdminPermissions from "./pages/MetaAdminPermissions";
import Users from "./pages/Users";
import Machines from "./pages/Machies";
import Organizations from "./pages/Organizations";

@observer
export default class App extends React.Component<{ store: Store }> {
    constructor(props: any) {
        super(props);

        this.init();
    }

    @action
    init = async () => {
        await this.props.store.check_perms();
    };

    render() {
        if (!this.props.store.loginState.isLoggedin) {
            return <Login store={this.props.store} />;
        } else {
            return (
                <BrowserRouter>
                    <Header store={this.props.store} />
                    <Container fluid>
                        <Row>
                            <Col>
                                <Alert show={!!this.props.store.error} variant="danger">
                                    {this.props.store.error}
                                </Alert>
                            </Col>
                        </Row>

                        <Routes>
                            <Route path="/" element={<Status store={this.props.store} />} />
                            <Route path="/stores" element={<Stores store={this.props.store} />} />
                            <Route path="/usage" element={<StoresUsage store={this.props.store} />} />
                            <Route path="/store/:storeid" element={<StoreShop store={this.props.store} />} />
                            <Route path="/skins/only" element={<SkinsOnly store={this.props.store} />} />
                            <Route path="/skins" element={<Skins store={this.props.store} />} />
                            <Route path="/finanaces/:id/invoices" element={<FinanacesView store={this.props.store} type="invoices" />} />
                            <Route path="/finanaces/:id/credit-notes" element={<FinanacesView store={this.props.store} type="credit-notes" />} />
                            <Route path="/finanaces" element={<Finanaces store={this.props.store} />} />
                            <Route path="/meta/permissions" element={<MetaAdminPermissions store={this.props.store} />} />
                            <Route path="/users" element={<Users store={this.props.store} />} />
                            <Route path="/machines" element={<Machines store={this.props.store} />} />
                            <Route path="/organizations" element={<Organizations store={this.props.store} />} />
                        </Routes>
                    </Container>
                </BrowserRouter>
            );
        }
    }
}
