import { action, makeObservable, observable, toJS } from 'mobx'
import consola from '../config/consola';
import moment, { Moment } from 'moment';
import jwt_decode from "jwt-decode";

import EventEmitter from 'events';
import { io, Socket } from 'socket.io-client'
import ShopamineApi from './apis/shopamine';
import StoreApi from './apis/store';
import TemplatesApi from './apis/templates';
import SkinApi from './apis/skin';
import FinancesApi from './apis/finaces';
import StoresApi from './apis/stores';
import AuthApi from './apis/auth';
import SystemApi from './apis/system';
import UsersApi from './apis/users';
import ChargeBeeApi from './apis/chargebee';
import MachinesApi from './apis/machines';
import DeployApi from './apis/deploy';
import OrganizationApi from './apis/organizations';

const logger = consola("store");

export default class Store extends EventEmitter {

    url = "";
    io: Socket;

    @observable
    page: string = "stores"
    @observable
    error?: string;
    @observable
    storesList: any = [];
    @observable
    storesUsageList: any = [];
    @observable
    skins: any = [];
    @observable
    permissions: string[] = [];
    @observable
    isAdmin = false;
    @observable
    config = {
        rawConfigKey: "",
    }
    @observable
    loginState = {
        isLoggedin: false
    }

    @observable
    currentRoute: string = "";

    private token?: string;
    public user_id?: string;

    public shopamineApi: ShopamineApi;
    public templatesApi: TemplatesApi;
    public financesApi: FinancesApi;
    public storesApi: StoresApi;
    public storeApi: StoreApi;
    public skinApi: SkinApi;
    public authApi: AuthApi;
    public systemApi: SystemApi;
    public usersApi: UsersApi;
    public machineApi: MachinesApi;
    public chargebeeApi: ChargeBeeApi;
    public deployApi: DeployApi;
    public organizationsApi: OrganizationApi;

    constructor() {
        super();

        this.shopamineApi = new ShopamineApi(this.url);
        this.templatesApi = new TemplatesApi(this.url);
        this.financesApi = new FinancesApi(this.url);
        this.storesApi = new StoresApi(this.url);
        this.storeApi = new StoreApi(this.url);
        this.skinApi = new SkinApi(this.url);
        this.authApi = new AuthApi(this.url);
        this.systemApi = new SystemApi(this.url);
        this.usersApi = new UsersApi(this.url);
        this.machineApi = new MachinesApi(this.url);
        this.chargebeeApi = new ChargeBeeApi(this.url);
        this.deployApi = new DeployApi(this.url);
        this.organizationsApi = new OrganizationApi(this.url);

        this.io = io(this.url, {
            query: {},
            transports: ['websocket']
        })

        makeObservable(this);

        logger.trace("Init");

        this.init();

        this.io.on('connect', () => logger.info('socket connected'));
        this.io.on('actors.status', (data) => this.emit('actors.status', data));
        this.io.on('deploy.update', (data) => this.emit('deploy.update', data));
        this.io.on('deploy.starting', (data) => this.emit('deploy.starting', data));
        this.io.on('deploy.done', (data) => this.emit('deploy.done', data));

    }

    @action
    private exec_session_update = async (token?: string) => {
        logger.trace(token);

        if (token) {
            this.update_apis(token);
            await this.check_perms();

            this.loginState.isLoggedin = true;
        } else {
            this.loginState.isLoggedin = false;
        }

        logger.info(`User is logged in: ${this.loginState.isLoggedin}`);
    }

    @action
    is_session_valid = async () => {
        if (await this.check_perms()) {
            return true;
        }
        return false;
    }

    @action
    init = async () => {
        try {
            const data = window.localStorage.getItem("token");

            if (data) {
                this.token = data;

                try {
                    const decoded = jwt_decode(data) as any;

                    this.user_id = decoded.id;

                    if (moment(decoded.exp, "X").isBefore()) {
                        window.localStorage.removeItem('token');
                        window.location.reload();
                    }
                } catch (e) {
                    logger.error(e);

                    window.localStorage.removeItem('token');
                    window.location.reload();
                }
            }

            await this.exec_session_update(this.token);
        } catch (e) {
            logger.error(e);
        }
    }

    update_session = async (input?: string): Promise<boolean> => {
        if (!input) {
            window.localStorage.removeItem('token');
        } else {
            window.localStorage.setItem('token', input);
        }

        if (input) {
            try {
                const decoded = jwt_decode(input) as any;

                this.user_id = decoded.id;

                if (moment(decoded.exp, "X").isBefore()) {
                    window.localStorage.removeItem('token');
                    window.location.reload();
                }
            } catch (e) {
                logger.error(e);

                window.localStorage.removeItem('token');
                window.location.reload();
            }
        }

        this.token = input;

        await this.exec_session_update(this.token);

        return true;
    }

    private update_apis = (token: string) => {
        this.shopamineApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.templatesApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.financesApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.storesApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.storeApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.skinApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.authApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.systemApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.usersApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.machineApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.chargebeeApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.deployApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        this.organizationsApi.update_config({
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    @action
    check_perms = async () => {
        try {
           const raw = (await this.authApi.check_perms());
           const { config, data } = raw;
            console.log(raw);

            this.isAdmin = data.is_admin;
            this.permissions = data.permissions;
            this.config = config;

            return true;
        } catch (e) {


            return false;
        }
    }

    can = (permission: string) => this.isAdmin || this.permissions.includes(permission);

    logout = async () => {
        window.localStorage.removeItem('token');
        window.location.reload();
    }

    download_admins = async () => {
        const csv = await this.storesApi.admins(true)

        var link = window.document.createElement('a')
        var data = new Blob([csv], { type: 'text/csv' })

        link.href = window.URL.createObjectURL(data)
        link.download = `admins.csv`

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    @action
    setCurrentSelectedRoute = (name: string) => {
        this.currentRoute = name;
    }
}