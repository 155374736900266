import React from "react";
import Store from "../lib/store";
import { observer } from "mobx-react";
import { action, makeObservable, observable, toJS } from "mobx";
import moment, { Moment } from "moment";
import _ from "lodash";
import { timeout } from "../lib/utils/utils";
import { withRouter } from "../lib/utils/react_utils";
import { Location, NavigateFunction, Params } from "react-router-dom";
import { Row, Col, Spinner, Alert, Tabs, Tab, Button, Form } from "react-bootstrap";
import Actions from "./StoreShop/Actions";
import StoreInfo from "./StoreShop/StoreInfo";
import StoreAccounting from "./StoreShop/StoreAccounting";
import StoreNotes from "./StoreShop/StoreNotes";
import StoreEmails from "./StoreShop/StoreEmails";
import Popup from "../ui/Popup";
import PopupSimple from "../ui/PopupSimple";
import Backup from "./StoreShop/Backup";
import StripeSettings from "./StoreShop/StripeSettings";
import StripeConfigure from "./StoreShop/StripeConfigure";
import ApiKeys from "./StoreShop/ApiKeys";
import Domains from "./StoreShop/Domains";
import Admins from "./StoreShop/Admins";
import Users from "./StoreShop/Users";
import ActivateStore from "./StoreShop/ActivateStore";
import { DispatchDoamin, DomainInfo, ShopamineInstance } from "shared/models";
import Backups from "./StoreShop/Backups";
import Chargebee from "./StoreShop/Chargebee";
import AdminView from "./StoreShop/AdminView";
import DeactivateStore from "./StoreShop/DeactivateStore";

@observer
class StoreShop extends React.Component<{ store: Store; router: { location: Location; navigate: NavigateFunction; params: Params<string> } }> {
    emailRegEx = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/);

    @observable
    hasError = false;
    @observable
    errorMessage = "";

    @observable
    popupHasError = false;
    @observable
    popupErrorMessage = "";

    @observable
    from: Moment = moment();
    @observable
    to: Moment = moment();
    @observable
    storeIndexData: any;
    @observable
    storeInfo: any;
    @observable
    storeUsersEmails: any = [];
    @observable
    domains: DomainInfo[] = [];

    @observable
    backup_data?: { email: string; send_backup: boolean; enc_password: string };

    @observable
    store_data = "";

    @observable
    storeid: number = 0;

    @observable
    is_activating_api = false;
    @observable
    is_activating_store = false;
    @observable
    is_deactivating_store = false;
    @observable
    is_backup_requesting = false;
    @observable
    is_saving_store_data = false;
    @observable
    is_creating_csv = false;
    @observable
    is_loading_emails = false;
    @observable
    is_loading_store_index = false;
    @observable
    is_loading_init = false;
    @observable
    is_stripe_requesting = false;
    @observable
    is_relaoding_config = false;
    @observable
    is_impersonation_in_progress = false;
    @observable
    is_activating_store_requesting = false;
    @observable
    is_deactivating_store_requesting = false;
    @observable
    is_backup_getting_url = false;
    @observable
    is_reseting_trail_config = false;
    @observable
    is_reindex_triggering = false;

    @observable
    stripe: any = {};
    @observable
    stripeAccounts: any[] = [];
    @observable
    apiKeys: any[] = [];
    @observable
    admins: any[] = [];
    @observable
    users: any[] = [];
    @observable
    activateApi: string[] = [];
    @observable
    instances: ShopamineInstance[] = [];
    @observable
    loading_instances: string[] = [];
    @observable
    backups: {
        auto: any[];
        request: any[];
    } = {
        auto: [],
        request: [],
    };
    @observable
    invoices: any[] = [];
    @observable
    admin_view_adminId = 0;
    @observable
    admin_view_stores: { storeId: number; storeName: string }[] = [];
    @observable
    admin_view_link = "";
    @observable
    admin_auth_privider_adminId = 0;
    @observable
    admin_auth_privider_email = "";
    @observable
    useDispatchDomain = false;
    @observable
    dispatchDomain?: DispatchDoamin;
    @observable
    deactivation_reason = "";

    @observable
    show_backup_popup = false;
    @observable
    show_stripe_popup = false;
    @observable
    show_activate_popup = false;
    @observable
    show_deactivate_popup = false;
    @observable
    show_admin_view_popup = false;
    @observable
    show_link_popup = false;
    @observable
    show_admin_link_control = false;
    @observable
    show_reset_trail_popup = false;

    constructor(props: any) {
        super(props);

        makeObservable(this);
        this.init();
    }

    @action
    init = async () => {
        try {
            this.props.store.setCurrentSelectedRoute("stores");
            if (!this.props.router.params.storeid) {
                throw new Error("Missing storeid");
            }

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            this.is_loading_init = true;

            this.storeid = Number.parseInt(this.props.router.params.storeid);

            const data = (await this.props.store.shopamineApi.info()).data;
            this.activateApi = data.activateApi;
            this.instances = data.instances;
            this.useDispatchDomain = data.useDispatchDomain;
            this.dispatchDomain = data.dispatchDomain;

            this.storeInfo = (await this.props.store.storeApi.get(this.storeid)).data;
            this.store_data = (await this.props.store.storeApi.notes(this.storeid)).data;
            this.domains = (await this.props.store.storeApi.domains(this.storeid)).data;

            this.stripeAccounts = (await this.props.store.storesApi.stripe_accounts()).data;
            this.stripe = (await this.props.store.storeApi.stripe_settings(this.props.router.params.storeid)).data;

            this.apiKeys = (await this.props.store.storeApi.apikeys(this.storeid)).data;

            if (this.storeInfo && this.storeInfo.instance && this.storeInfo.instance.Loading_Instances) {
                this.loading_instances = _.chain(this.storeInfo.instance.Loading_Instances.split(","))
                    .map((p: string) => p.trim().replace("instance_", ""))
                    .uniq()
                    .value() as any[];
            }

            await this.load_emails(false);

            this.backups.auto = (await this.props.store.storeApi.backups_list(this.storeid, "auto")).data;
            this.backups.request = (await this.props.store.storeApi.backups_list(this.storeid, "request")).data;
            this.invoices = (await this.props.store.storeApi.invoices(this.storeid)).data;

            this.admins = (await this.props.store.storeApi.admins(this.storeid)).data;
            this.users = (await this.props.store.storeApi.users(this.storeid)).data;

            await timeout(200);
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_loading_init = false;
        }
    };

    @action
    reload_backups = async () => {
        try {
            if (!this.props.router.params.storeid) {
                throw new Error("Missing storeid");
            }

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            this.is_loading_init = true;

            this.storeid = Number.parseInt(this.props.router.params.storeid);

            this.backups.auto = (await this.props.store.storeApi.backups_list(this.storeid, "auto")).data;
            this.backups.request = (await this.props.store.storeApi.backups_list(this.storeid, "request")).data;

            await timeout(200);
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_loading_init = false;
        }
    };

    @action
    load_emails = async (purchaseOnly: boolean) => {
        try {
            if (!this.props.router.params.storeid) {
                throw new Error("Missing storeid");
            }

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            this.is_loading_emails = true;

            this.storeid = Number.parseInt(this.props.router.params.storeid);

            this.storeUsersEmails = (await this.props.store.storeApi.user_emails(this.storeid, purchaseOnly)).data;
            await timeout(200);
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_loading_emails = false;
        }
    };

    @action
    load_store_index = async (from: Moment, to: Moment) => {
        try {
            if (!this.props.router.params.storeid) {
                throw new Error("Missing storeid");
            }

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            this.is_loading_store_index = true;

            this.storeid = Number.parseInt(this.props.router.params.storeid);

            this.storeIndexData = (await this.props.store.storeApi.usage(this.storeid, this.from, this.to)).data;
            await timeout(200);
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_loading_store_index = false;
        }
    };

    @action
    store_users_emails_csv = async () => {
        try {
            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            this.is_creating_csv = true;

            let csv = "Email";

            for (const email of this.storeUsersEmails) {
                csv += `\n${email.Email}`;
            }

            var link = window.document.createElement("a");
            var data = new Blob([csv], { type: "text/csv" });

            link.href = window.URL.createObjectURL(data);
            link.download = `StoresUsage-${this.from.format("DD-MM-YYYY")}-${this.to.format("DD-MM-YYYY")}.csv`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            await timeout(200);
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_creating_csv = false;
        }
    };

    @action
    create_backup = async () => {
        try {
            if (!this.props.router.params.storeid) {
                throw new Error("Missing storeid");
            }

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            if (!this.backup_data) {
                throw new Error("Popup not opend???? Hacking a?");
            }

            if (!this.emailRegEx.test(this.backup_data.email)) {
                throw new Error("Invalid email");
            }

            this.is_backup_requesting = true;

            this.storeid = Number.parseInt(this.props.router.params.storeid);

            await this.props.store.storeApi.create_backup(this.storeid, this.backup_data.email, this.backup_data.enc_password, this.backup_data.send_backup ? 1 : 0);

            this.show_backup_popup = false;

            await timeout(200);

            await this.reload_backups();
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.popupErrorMessage = e.response.data.errMsg;
                } else {
                    this.popupErrorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        } finally {
            this.is_backup_requesting = false;
        }
    };

    @action
    set_store_data = async (store_data: string) => {
        try {
            if (!this.props.router.params.storeid) {
                throw new Error("Missing storeid");
            }

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            this.is_saving_store_data = true;

            await this.props.store.storeApi.save_notes(this.storeid, store_data);

            await timeout(200);
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_saving_store_data = false;
        }
    };

    @action
    activate_store = async () => {
        try {
            this.is_activating_store = true;

            if (!this.props.router.params.storeid) {
                throw new Error("Missing storeid");
            }

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            this.storeid = Number.parseInt(this.props.router.params.storeid);

            await this.props.store.storeApi.activate(this.storeid, this.loading_instances);
            await this.props.store.storeApi.load_by_instances(this.storeid, this.loading_instances);

            await timeout(200);

            this.show_activate_popup = false;

            await this.init();
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_activating_store = false;
        }
    };

    @action
    activate_store_open_popup = async () => {
        this.show_activate_popup = true;
    };

    @action
    deactivate_store = async () => {
        try {
            this.is_deactivating_store = true;

            if (!this.props.router.params.storeid) {
                throw new Error("Missing storeid");
            }

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            this.storeid = Number.parseInt(this.props.router.params.storeid);

            await this.props.store.storeApi.deactivate(this.storeid, this.deactivation_reason);

            await timeout(200);

            this.show_deactivate_popup = false;

            await this.init();
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_deactivating_store = false;
        }
    }

    @action
    deactivate_store_open_popup = async () => {
        this.show_deactivate_popup = true;
        this.deactivation_reason = "";
    };

    @action
    reload_store_config = async () => {
        try {
            this.is_relaoding_config = true;

            if (!this.props.router.params.storeid) {
                throw new Error("Missing storeid");
            }

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            this.storeid = Number.parseInt(this.props.router.params.storeid);

            await this.props.store.storeApi.reload_config(this.storeid);

            await timeout(200);
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_relaoding_config = false;
        }
    };

    @action
    update_stripe = async () => {
        try {
            this.is_stripe_requesting = true;

            if (!this.props.router.params.storeid) {
                throw new Error("Missing storeid");
            }

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            await this.props.store.storeApi.update_stripe_settings(this.props.router.params.storeid, this.stripe.Stripe_Account_ID, this.stripe.Shopamine_Fee_Rate);

            this.stripe = (await this.props.store.storeApi.stripe_settings(this.props.router.params.storeid)).data;

            await timeout(200);

            this.show_stripe_popup = false;
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.popupErrorMessage = e.response.data.errMsg;
                } else {
                    this.popupErrorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        } finally {
            this.is_stripe_requesting = false;
        }
    };

    @action
    impersonateAdmin = async (adminId: number, domain: string) => {
        try {
            this.is_impersonation_in_progress = true;

            if (!this.props.router.params.storeid) {
                throw new Error("Missing storeid");
            }

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            const url = (await this.props.store.storeApi.impersonate_admin(this.loading_instances[0], Number.parseInt(this.props.router.params.storeid), adminId, domain)).data;

            window.open(url, "_newtab", "noopener=true");

            // console.log(url);
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_impersonation_in_progress = false;
        }
    };

    @action
    impersonateUser = async (userId: number, domain: string) => {
        try {
            this.is_impersonation_in_progress = true;

            if (!this.props.router.params.storeid) {
                throw new Error("Missing storeid");
            }

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            const url = (await this.props.store.storeApi.impersonate_user(this.loading_instances[0], Number.parseInt(this.props.router.params.storeid), userId, domain)).data;

            window.open(url, "_newtab", "noopener=true");
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_impersonation_in_progress = false;
        }
    };

    @action
    download = async (key: string) => {
        try {
            this.is_backup_getting_url = true;

            if (!this.props.router.params.storeid) {
                throw new Error("Missing storeid");
            }

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            const url = (await this.props.store.storeApi.backups_get(Number.parseInt(this.props.router.params.storeid), key)).data;

            window.open(url, "_newtab");
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.popupErrorMessage = e.response.data.errMsg;
                } else {
                    this.popupErrorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        } finally {
            this.is_backup_getting_url = false;
        }
    };

    @action
    viewAdminUser = async (adminId: number) => {
        try {
            if (!this.props.router.params.storeid) {
                throw new Error("Missing storeid");
            }

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            this.show_admin_view_popup = true;
            this.admin_view_adminId = adminId;
            this.admin_view_stores = (await this.props.store.storeApi.admin_stores(this.loading_instances[0], Number.parseInt(this.props.router.params.storeid), adminId)).data;
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.popupErrorMessage = e.response.data.errMsg;
                } else {
                    this.popupErrorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    @action
    getAuthFlowAuthorizationLink = async (adminId: number, domain: string) => {
        try {
            if (!this.props.router.params.storeid) {
                throw new Error("Missing storeid");
            }

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            const link = (await this.props.store.storeApi.authorize_link(this.loading_instances[0], Number.parseInt(this.props.router.params.storeid), adminId, domain)).data;

            this.show_link_popup = true;
            this.admin_view_link = link;
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.popupErrorMessage = e.response.data.errMsg;
                } else {
                    this.popupErrorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    @action
    removeAdminStores = async (adminId: number) => {
        try {
            if (!this.props.router.params.storeid) {
                throw new Error("Missing storeid");
            }

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            this.admin_view_stores = (await this.props.store.storeApi.remove_admin_stores(this.loading_instances[0], Number.parseInt(this.props.router.params.storeid), adminId)).data;
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.popupErrorMessage = e.response.data.errMsg;
                } else {
                    this.popupErrorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    @action
    openLinkUserPopup = async (adminId: number) => {
        this.show_admin_link_control = true;
        this.admin_auth_privider_adminId = adminId;
    };

    @action
    onLinkAuthAdminUser = async () => {
        try {
            if (!this.props.router.params.storeid) {
                throw new Error("Missing storeid");
            }

            if (!this.props.store.can("stores")) {
                throw new Error("No access!!!!");
            }

            await this.props.store.storeApi.link_admin(this.loading_instances[0], Number.parseInt(this.props.router.params.storeid), this.admin_auth_privider_adminId, this.admin_auth_privider_email);
            this.admin_view_stores = (await this.props.store.storeApi.admin_stores(this.loading_instances[0], Number.parseInt(this.props.router.params.storeid), this.admin_auth_privider_adminId)).data;

            this.show_admin_link_control = false;
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.popupErrorMessage = e.response.data.errMsg;
                } else {
                    this.popupErrorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        }
    };

    @action
    chargebee_reset_trail = async () => {
        try {
            if (!this.props.router.params.storeid) {
                throw new Error("Missing storeid");
            }

            if (!this.props.store.can("store_reset_trail")) {
                throw new Error("No access!!!!");
            }

            this.is_reseting_trail_config = true;

            await this.props.store.storeApi.chargebee_reset_trial(this.props.router.params.storeid);

            this.show_reset_trail_popup = false;
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.popupErrorMessage = e.response.data.errMsg;
                } else {
                    this.popupErrorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.popupErrorMessage = `We got unspecified error: ${e}`;
            }

            this.popupHasError = true;

            await timeout(2000);
            this.popupHasError = false;
        } finally {
            this.is_reseting_trail_config = false;
        }
    };

    @action
    chargebee_reset_trail_popup = () => {
        this.show_reset_trail_popup = true;
    };

    @action
    trigger_elastic_reindex = async () => {
        try {
            this.is_reindex_triggering = true;

            if (!this.storeInfo.custom_function) {
                throw new Error("No custom function");
            }

            if (!this.storeInfo.custom_function.elasticSearchSettings) {
                throw new Error("No elastic search settings");
            }

            if (!this.storeInfo.custom_function.elasticSearchSettings.enabled) {
                throw new Error("Elastic search is not enabled");
            }

            const domains: any[] = this.storeInfo.domains;

            let selectedDomain;
            let firstDomain;
            for(const domain of domains) {
                if(!firstDomain) {
                    firstDomain = domain.domain
                }

                if(domain.domain.indexOf("shopamine") !== -1) {
                    selectedDomain = domain.domain;
                }
            }

            let domain = selectedDomain;
            if (selectedDomain === undefined && domains.length > 0) {
                domain = firstDomain;
            } 

            let storeAccessDomain = domain;

            if (this.useDispatchDomain && this.dispatchDomain) {
                storeAccessDomain = this.dispatchDomain.domain + "/" + domain;
            }

            window.open(`https://${storeAccessDomain}/api/custom/elasticsearch?t=${this.storeInfo.custom_function.elasticSearchSettings.updaterKey}&recreate-index`, "_newtab", "noopener=true");
        } catch (eRaw) {
            const e = eRaw as any;

            if (e.response && e.response.status === 403) {
                if (!(await this.props.store.is_session_valid())) {
                    await this.props.store.logout();
                }
            }

            if (e.response && e.response.data) {
                console.log("here", e.response.data);

                if (typeof e.response.data.errMsg === "string") {
                    this.errorMessage = e.response.data.errMsg;
                } else {
                    this.errorMessage = JSON.stringify(e.response.data.errMsg);
                }
            } else {
                this.errorMessage = `We got unspecified error: ${e}`;
            }

            this.hasError = true;

            await timeout(3000);
            this.hasError = false;
        } finally {
            this.is_reindex_triggering = false;
        }
    };

    render() {
        return (
            <>
                <Row>
                    <Col>
                        <h2>Store {this.storeid}</h2>

                        <p>
                            Running on <code>{this.storeInfo && this.storeInfo.instance && this.storeInfo.instance.Loading_Instances}</code> using db: <code>{this.storeInfo && this.storeInfo.instance && this.storeInfo.instance.DbHost}</code>
                        </p>

                        <Alert show={this.hasError} variant="danger">
                            {this.errorMessage}
                        </Alert>

                        <Actions store={this.props.store} domains={this.storeInfo && _.map(this.storeInfo.domains, (entry) => entry.domain)} dispatchDomain={this.dispatchDomain} activate_store={this.activate_store_open_popup} is_activating_store={this.is_activating_store} create_backup={action(() => (this.show_backup_popup = true))} is_activating_api={this.is_activating_api} is_relaoding_config={this.is_relaoding_config} reload_store_config={this.reload_store_config} chargebee_reset_trail={this.chargebee_reset_trail_popup} is_reseting_trail_config={this.is_reseting_trail_config} trigger_elastic_reindex={this.trigger_elastic_reindex} is_reindex_triggering={this.is_reindex_triggering} deactivate_store={this.deactivate_store_open_popup} is_deactivating_store={this.is_deactivating_store} />

                        <Tabs defaultActiveKey="store" id="uncontrolled-tab-example" className="mt-20 mb-3">
                            <Tab eventKey="store" title="Store info">
                                <Row className="mb-20">
                                    <Col md="3">
                                        <StoreInfo store_info={this.storeInfo} dispatchDomain={this.dispatchDomain} />
                                    </Col>

                                    <Col md="2">
                                        <StripeSettings store_info={this.storeInfo} stripeAccounts={this.stripeAccounts} stripe={this.stripe} activate={action(() => (this.show_stripe_popup = true))} />
                                    </Col>

                                    <Col>
                                        <StoreNotes set_store_data={this.set_store_data} store_data={this.store_data} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="4">
                                        <ApiKeys apiKeys={this.apiKeys} />
                                    </Col>
                                    <Col md="4">
                                        <Domains domains={this.domains} dispatchDomain={this.dispatchDomain} />
                                    </Col>
                                    <Col>
                                        <Admins store={this.props.store} dispatchDomain={this.dispatchDomain} admins={this.admins} impersonate={this.impersonateAdmin} domains={this.storeInfo && _.map(this.storeInfo.domains, (entry) => entry.domain)} viewAdminUser={this.viewAdminUser} />
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="stats" title="Stats">
                                <Row className="mb-20">
                                    <Col md="4">
                                        <StoreAccounting load_store_index={this.load_store_index} store_index_data={this.storeIndexData} />
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="adminEmails" title="Store users">
                                <Row className="mb-20">
                                    <Col md="8">
                                        <Users dispatchDomain={this.dispatchDomain} users={this.users} impersonate={this.impersonateUser} domains={this.storeInfo && _.map(this.storeInfo.domains, (entry) => entry.domain)} />
                                    </Col>
                                    <Col>
                                        <StoreEmails emails={this.storeUsersEmails} load_emails={this.load_emails} store_users_emails_csv={this.store_users_emails_csv} />
                                    </Col>
                                </Row>
                            </Tab>

                            <Tab eventKey="backups" title="Backups">
                                <Row className="mb-20">
                                    <Col>
                                        <Backups backups={this.backups.auto ? this.backups.auto : []} type="auto" download={this.download} reload={this.reload_backups} />
                                    </Col>
                                    <Col>
                                        <Backups backups={this.backups.request ? this.backups.request : []} type="request" download={this.download} reload={this.reload_backups} />
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="chargebee" title="Chargebee">
                                <Row className="mb-20">
                                    <Col>
                                        <Chargebee invoices={this.invoices} />
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>

                <Popup show={this.show_backup_popup} onClose={action(() => (this.show_backup_popup = false))} onSave={this.create_backup} title="Create backup" disabled={this.is_backup_requesting} save={<>{this.is_backup_requesting && <Spinner size="sm" as="span" animation="border" />} Create</>}>
                    <Backup popupHasError={this.popupHasError} popupErrorMessage={this.popupErrorMessage} update={action((data) => (this.backup_data = data))} />
                </Popup>

                <Popup show={this.show_stripe_popup} onClose={action(() => (this.show_stripe_popup = false))} onSave={this.update_stripe} title="Strip" disabled={this.is_stripe_requesting} save={<>{this.is_stripe_requesting && <Spinner size="sm" as="span" animation="border" />} Save</>}>
                    <StripeConfigure popupHasError={this.popupHasError} popupErrorMessage={this.popupErrorMessage} update={action((data) => (this.stripe = data))} stripeAccounts={this.stripeAccounts} stripe={this.stripe} />
                </Popup>

                <Popup show={this.show_activate_popup} onClose={action(() => (this.show_activate_popup = false))} onSave={this.activate_store} title="Activate store" disabled={this.is_activating_store_requesting} save={<>{this.is_activating_store_requesting && <Spinner size="sm" as="span" animation="border" />} Save</>}>
                    <ActivateStore popupHasError={this.popupHasError} popupErrorMessage={this.popupErrorMessage} update={action((data) => (this.loading_instances = data.instances))} instances={this.instances} loading_instances={this.loading_instances} />
                </Popup>

                <Popup show={this.show_deactivate_popup} onClose={action(() => (this.show_deactivate_popup = false))} onSave={this.deactivate_store} title="Deactivate store" disabled={this.is_deactivating_store_requesting} save={<>{this.is_deactivating_store_requesting && <Spinner size="sm" as="span" animation="border" />} Save</>}>
                    <DeactivateStore  popupHasError={this.popupHasError} popupErrorMessage={this.popupErrorMessage} update={action((data) => (this.deactivation_reason = data))} />
                </Popup>

                <PopupSimple show={this.show_admin_view_popup} onClose={action(() => (this.show_admin_view_popup = false))} title="Admin view">
                    <AdminView popupHasError={this.popupHasError} popupErrorMessage={this.popupErrorMessage} admin={_.find(this.admins, (admin) => admin.ID === this.admin_view_adminId)} admin_view_stores={this.admin_view_stores} domains={this.storeInfo && _.map(this.storeInfo.domains, (entry) => entry.domain)} getAuthFlowAuthorizationLink={this.getAuthFlowAuthorizationLink} openLinkUserPopup={this.openLinkUserPopup} removeAdminStores={this.removeAdminStores} />
                </PopupSimple>

                <PopupSimple show={this.show_link_popup} onClose={action(() => (this.show_link_popup = false))} title="Link">
                    <pre>{this.admin_view_link}</pre>
                    <Button onClick={() => window.navigator.clipboard.writeText(this.admin_view_link)}>Copy</Button>
                </PopupSimple>

                <Popup show={this.show_admin_link_control} onClose={action(() => (this.show_admin_link_control = false))} title="Link admin user" onSave={this.onLinkAuthAdminUser} save={"Link user"}>
                    <Alert variant="danger" show={this.popupHasError}>
                        {this.popupErrorMessage}
                    </Alert>

                    <Form.Group className="mb-3">
                        <Form.Label>Auth provider Email</Form.Label>
                        <Form.Control type="text" value={this.admin_auth_privider_email} onChange={action((e) => (this.admin_auth_privider_email = e.target.value))} />
                    </Form.Group>
                </Popup>

                <Popup show={this.show_reset_trail_popup} onClose={action(() => (this.show_reset_trail_popup = false))} title="Reset trail" onSave={this.chargebee_reset_trail} save={"Yes"}>
                    <Alert variant="danger" show={this.popupHasError}>
                        {this.popupErrorMessage}
                    </Alert>
                    <p>You are about to reset plan and trail status for store in chargebee connection. Are you sure?</p>
                    <p>Note: this will not reset Shopamine's internal trail timestamp!</p>
                </Popup>
            </>
        );
    }
}

export default withRouter(StoreShop);
